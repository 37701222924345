import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import { Auth } from "aws-amplify";

const useStyles = makeStyles(theme => ({
	button: {
		cursor: "pointer",
	},
	nursaHeader: {
		textAlign: "center",
		padding: "15px",
		background: "#ffffff",
		boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.12)",
	},
	nursaLogo: {
		maxWidth: "130px",
	},
}));

export function Header({ username, setUsername }) {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Grid container direction="row" className={classes.nursaHeader}>
			<Grid item xs={4}></Grid>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				xs={4}
			>
				<img
					id="nursaLogo"
					src="/nursa-logo.png"
					alt="Nursa Logo"
					className={classes.nursaLogo}
				/>
			</Grid>
			{username && (
				<Grid container item justifyContent="flex-end" xs={4}>
					<div>
						<Button
							id="basic-button"
							aria-controls={open ? "basic-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
							onClick={handleClick}
						>
							{username}
						</Button>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
							transformOrigin={{
								horizontal: "left",
								vertical: -36.5,
								// vertical: "top",
							}}
							PaperProps={{
								sx: {
									width: "100%",
								},
							}}
						>
							<MenuItem
								onClick={() => {
									setUsername("");
									Auth.signOut();
									handleClose();
								}}
								styles={{ width: "100%" }}
							>
								Logout
							</MenuItem>
						</Menu>
					</div>
					{/* <button
						className={classes.button}
						onClick={() => {
							setUsername("");
							Auth.signOut();
						}}
					>
						Sign Out ({username})
					</button> */}
				</Grid>
			)}
			{/* </div> */}
		</Grid>
	);
}
