import Container from "@material-ui/core/Container";
import Amplify from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import Embed from "./Embed";
import "@aws-amplify/ui-react/styles.css";
import { useState } from "react";
import { Header } from "./Header";

Amplify.configure(awsconfig);

function App() {
	const [username, setUsername] = useState("");

	return (
		<Authenticator>
			<div className="App">
				<Header username={username} setUsername={setUsername} />
				<Container maxWidth="xl" style={{ paddingTop: "24px" }}>
					<Embed setUsername={setUsername} />
				</Container>
			</div>
		</Authenticator>
	);
}

export default App;
