import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";

const useStyles = theme => ({
	loading: {
		alignContent: "center",
		justifyContent: "center",
		display: "flex",
		marginTop: theme.spacing(4),
	},
	container: {
		height: "88vh",
	},
});

const Embed = ({ setUsername, classes }) => {
	const [hasLoader, setHasLoader] = useState(true);

	useEffect(() => {
		// prevent double render]
		async function fetchData() {
			// console.log("calling getQuickSightDashboardEmbedURL");
			const data = await Auth.currentSession();
			const jwtToken = data.idToken.jwtToken;
			const payloadSub = data.idToken.payload.sub;
			const email = data.idToken.payload.email;

			// console.log(data.idToken.payload);

			const params = {
				headers: {},
				response: true,
				queryStringParameters: {
					jwtToken: jwtToken,
					payloadSub: payloadSub,
					email: email,
				},
			};
			// console.log("params", params);
			const quicksight = await API.get(
				"quicksight",
				"/getQuickSightDashboardEmbedURL",
				params
			);
			// console.log("quicksight", quicksight);
			const containerDiv = document.getElementById("dashboardContainer");
			if (containerDiv.innerHTML === "") {
				const options = {
					url: quicksight.data.data.EmbedUrl,
					container: containerDiv,
					parameters: {
						country: "United States",
					},
					scrolling: "no",
					width: "100%",
					height: "100%",
					footerPaddingEnabled: true,
				};
				embedDashboard(options);
				setHasLoader(false);
				Auth.currentAuthenticatedUser({
					bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				})
					.then(user => setUsername(user.username))
					.catch(err => console.log(err));
			}
		}
		fetchData();
	}, []);
	return (
		<div>
			{hasLoader && (
				<div className={classes.loading}>
					<CircularProgress />
				</div>
			)}
			<div id="dashboardContainer" className={classes.container}></div>
		</div>
	);
};

export default withStyles(useStyles)(Embed);
